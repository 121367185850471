<template>
	<div class="container-fluid h-100 px-0 fundo-cinza">
		<div class="card h-100 align-items-center">
			<div class="card-body bg-white only-overflow-y px-3 py-1 w-75">
				<div class="row">
					<div :class="[{ 'form-group col-12': true }, { 'has-warning': NaturezasOperacao.length == 0 }]">
						<label for="selectNatOper" class="input-required">Natureza de Operação</label>
						<div class="input-group input-group-sm">
							<select
								ref="selectNatOper"
								id="lmxta-configuracoes-select-naturezaoperacao"
								class="form-control form-control-sm"
								tabindex="1"
								v-model="DadosSelecionados.NaturezaOperacao"
								v-tooltip.bottom="apresentarTooltip('Informe a natureza de operação a ser utilizada')"
							>
								<option
									id="lmxta-configuracoes-option-naturezaoperacao"
									v-for="NaturezaOperacao in NaturezasOperacao"
									:value="NaturezaOperacao"
									v-bind:key="NaturezaOperacao.Codigo"
								>
									{{ NaturezaOperacao.Id }} -
									{{ NaturezaOperacao.Descricao }}
								</option>
							</select>
						</div>
					</div>

					<div :class="[{ 'form-group col-12': true }, { 'has-warning': Series.length == 0 }]">
						<label for="selectSerie" class="input-required">Série</label>
						<div class="input-group input-group-sm">
							<select
								id="lmxta-configuracoes-select-serie"
								class="form-control form-control-sm"
								tabindex="2"
								v-model="DadosSelecionados.Serie"
								v-tooltip.bottom="apresentarTooltip('Informe a série que será utilizada na nota')"
							>
								<option class="lmxta-configuracoes-option-serie" v-for="Serie in Series" :value="Serie" v-bind:key="Serie.Serie">
									{{ Serie.Serie }}
								</option>
							</select>
						</div>
					</div>

					<div :class="[{ 'form-group col-12': true }, { 'has-warning': Depositos.length == 0 }]">
						<label for="selectDeposito" class="input-required">Depósito</label>
						<div class="input-group input-group-sm">
							<select
								id="lmxta-configuracoes-select-deposito"
								v-model="DadosSelecionados.Deposito"
								tabindex="3"
								class="form-control form-control-sm"
								v-tooltip.bottom="apresentarTooltip('Informe o depósito desejado')"
							>
								<option
									class="lmxta-configuracoes-option-deposito"
									v-for="Deposito in Depositos"
									:value="Deposito"
									v-bind:key="Deposito.Id"
								>
									{{ Deposito.Nome }} ({{ Deposito.Id }})
								</option>
							</select>
						</div>
					</div>

					<div :class="[{ 'form-group col-12': true }, { 'has-warning': Depositos.length == 0 }]">
						<label for="selectCentroCusto" class="input-required">Centro de Custo da Devolução:</label>
						<div class="input-group input-group-sm">
							<select
								id="lmxta-configuracoes-select-centro-custo"
								v-model="DadosSelecionados.IdCentroCustoDevolucao"
								tabindex="3"
								class="form-control form-control-sm"
								v-tooltip.bottom="apresentarTooltip('Selecione um centro de custo')"
							>
								<option value="null" selected>Conforme a operação padrão</option>
								<option
									class="lmxta-configuracoes-option-centro-custo"
									v-for="centroCusto in centrosCusto"
									:value="centroCusto.Id"
									v-bind:key="centroCusto.Id"
								>
								{{ centroCusto.Nome }}
								({{ centroCusto.Id }})
								</option>
							</select>
						</div>
					</div>

					<div class="form-group col-12 custom-control custom-checkbox d-flex justify-content-start">
						<input
							type="checkbox"
							v-model="DadosSelecionados.MostraIcmsStObs"
							id="lmxta-mostra-icms-st-obs"
							class="custom-control-input position-static"
						/>
						<label for="lmxta-mostra-icms-st-obs" class="custom-control-label nome-tela"
							>Enviar o ICMS ST no campo de Despesas Acessórias e no campo de Informações Complementares e não em campo
							próprio</label
						>
					</div>

					<div class="form-group col-12 custom-control custom-checkbox d-flex justify-content-start">
						<input
							type="checkbox"
							v-model="DadosSelecionados.EmitirNotasPorChaveOrigem"
							id="lmxta-emitir-nf-por-chave"
							class="custom-control-input position-static"
						/>
						<label for="lmxta-emitir-nf-por-chave" class="custom-control-label nome-tela"
							>Emitir Nota Fiscal para cada Chave de Origem</label
						>
					</div>

					<div class="form-group col-12 custom-control custom-checkbox d-flex justify-content-start">
						<div>
							<button id="lmxta-configuracoes-btn-salvar" class="btn btn-default" @click="abrirOperacaoPadrao">
								<span class="text-center">Operação Padrão</span>
							</button>
						</div>
						<span class="ml-2 tooltip-preferencias">
							<i class="fa fa-info-circle fa-lg" aria-hidden="true" style="color: #5b2e90"></i>
							<span class="tooltiptext ml-2">
								<div>Utilize o botão "Operação Padrão" para selecionar</div>
								<div>as contas contábeis da Devolução de Compra.</div>
							</span>
						</span>
					</div>
				</div>
			</div>
			<div class="card-footer w-100 fundo-cinza">
				<div
					:class="{
						'd-flex align-items-center': true,
						'justify-content-between': !ehPrimeiroAcesso,
						'justify-content-end': ehPrimeiroAcesso,
					}"
				>
					<button
						id="lmxta-configuracoes-btn-cancelar"
						type="button"
						class="btn btn-default"
						tabindex="8"
						@click="cancelar"
						v-if="!ehPrimeiroAcesso"
					>
						<span class="text-center">Cancelar</span>
					</button>
					<button id="lmxta-configuracoes-btn-salvar" class="btn btn-primary" tabindex="8" @click="salvarConfiguracoes">
						<span class="text-center">Salvar</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import swal from '@/common/alerts';
import loading from '@/common/loading';
import { mapState, mapGetters } from 'vuex';
import VTooltip from 'v-tooltip';
import Vue from 'vue';

Vue.use(VTooltip);

export default {
	name: 'Configuracoes',
	data() {
		return {
			Series: [],
			NaturezasOperacao: [],
			Depositos: [],
			DadosSelecionados: {
				NaturezaOperacao: null,
				Serie: null,
				Deposito: null,
				MostraIcmsStObs: true,
				EmitirNotasPorChaveOrigem: false,
				IdCentroCustoDevolucao: null,
			},
			urlOperacaoPadrao:
				'gestor_web/financeiro/contabilidade/operacoes_contab.asp?origem=devolucaoCompra&idOperacao=Devolução+de+Compra+(12)',
		};
	},

	mounted() {
		loading.exibir();

		setTimeout(() => {
			loading.ocultar();
		}, 500);
	},

	created: function () {
		const store = this.$store.state.devolucao;

		this.NaturezasOperacao = store.Configuracoes.listasGerais.NaturezasOperacao;
		this.Series = store.Configuracoes.listasGerais.Series;
		this.Depositos = store.Configuracoes.listasGerais.Depositos;

		if (this.ehPrimeiroAcesso) {
			this.DadosSelecionados.NaturezaOperacao = this.NaturezasOperacao.length > 0 ? this.NaturezasOperacao[0] : null;
			this.DadosSelecionados.Serie = this.Series.length > 0 ? this.Series[0] : null;
			this.DadosSelecionados.Deposito = this.Depositos.length > 0 ? this.Depositos[0] : null;
			this.DadosSelecionados.IdCentroCustoDevolucao = null;
		} else {
			const naturezaOperacao = this.NaturezasOperacao.find(
				(nat) => nat.Codigo == store.Configuracoes.preferenciasEmpresa.CodigoNaturezaOperacao,
			);
			this.DadosSelecionados.NaturezaOperacao = naturezaOperacao ?? null;

			const serie = this.Series.find((serie) => serie.Serie == store.Configuracoes.preferenciasEmpresa.Serie);
			this.DadosSelecionados.Serie = serie ?? null;

			const deposito = this.Depositos.find((deposito) => deposito.Id == store.Configuracoes.preferenciasEmpresa.IdDeposito);
			this.DadosSelecionados.Deposito = deposito ?? null;

			this.DadosSelecionados.MostraIcmsStObs = store.Configuracoes.preferenciasEmpresa.MostraIcmsStObs;
			this.DadosSelecionados.EmitirNotasPorChaveOrigem = store.Configuracoes.preferenciasEmpresa.EmitirNotasPorChaveOrigem;
			this.DadosSelecionados.IdCentroCustoDevolucao = store.Configuracoes.preferenciasEmpresa.IdCentroCustoDevolucao
		}
	},

	computed: {
		...mapGetters({
			ehPrimeiroAcesso: 'devolucao/ehPrimeiroAcesso',
		}),

		...mapState({
			centrosCusto: (state) => state.devolucao.Configuracoes.listasGerais.CentrosCusto,
		}),

		bootstrapConflict() {
			return {
				template:
					'<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner" style="border-radius: 10px; font-size: 0.8rem"></div></div>',
				arrowSelector: '.tooltip-vue-arrow, .tooltip-vue__arrow',
				innerSelector: '.tooltip-vue-inner, .tooltip-vue__inner',
			};
		},
	},

	methods: {
		apresentarTooltip(mensagem) {
			return { content: mensagem, ...this.bootstrapConflict };
		},

		salvarConfiguracoes: function () {
			const mensagemValidacao = this.validaDadosPreferencias();
			if (mensagemValidacao.length > 0) {
				swal.exibirMensagemErro(mensagemValidacao.join('<br />'));
				return;
			}

			const configuracoes = {
				Serie: this.DadosSelecionados.Serie.Serie,
				CodigoNaturezaOperacao: this.DadosSelecionados.NaturezaOperacao.Codigo,
				IdDeposito: this.DadosSelecionados.Deposito.Id,
				MostraIcmsStObs: this.DadosSelecionados.MostraIcmsStObs,
				EmitirNotasPorChaveOrigem: this.DadosSelecionados.EmitirNotasPorChaveOrigem,
				IdCentroCustoDevolucao: this.DadosSelecionados.IdCentroCustoDevolucao
			};

			loading.exibir();

			this.$store
				.dispatch('devolucao/salvarPreferencias', configuracoes)
				.then(async (response) => {
					loading.ocultar();
					swal.exibirMensagemSucesso('Configurações salvas com sucesso.', null, true);
					this.$router.push('/devolucao/identificacao');
				})
				.catch((error) => {
					console.log(error);
					loading.ocultar();
					swal.exibirMensagemErro(error.join('<br />'));
				});
		},

		selecionarSerie: function () {
			const serie = this.SeriePorTipoVenda[0];
			this.DadosSelecionados.Serie = serie;
		},
		validaDadosPreferencias: function () {
			let mensagensErro = [];

			if (!this.DadosSelecionados.NaturezaOperacao) mensagensErro.push('Natureza de Operação não informada');

			if (!this.DadosSelecionados.Serie) mensagensErro.push('Série não informada');

			if (!this.DadosSelecionados.Deposito) mensagensErro.push('Depósito não informado');

			return mensagensErro;
		},

		abrirOperacaoPadrao() {
			this.abrirJanelaErp(this.urlOperacaoPadrao);
		},

		abrirJanelaErp(path) {
			let downloadWindow = window.open(this.$store.state.devolucao.urlBaseERP + path, '_blank');
		},

		cancelar: function () {
			this.$router.go(-1);
		},
	},
};
</script>

<style scoped>
.tooltip-preferencias .tooltiptext {
	visibility: hidden;
	width: max-content;
	background-color: rgba(91, 46, 144, 1);
	color: #fff;
	text-align: left;
	border-radius: 6px;
	padding: 5px 10px;
	position: absolute;
	z-index: 1;
	left: auto;
}

.tooltip-preferencias .tooltiptext::after {
	content: '';
	position: absolute;
	top: 7%;
	right: 100%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent rgba(91, 46, 144, 0.9) transparent transparent;
}

.tooltip-preferencias:hover .tooltiptext {
	visibility: visible;
}

.configuracoes-content {
	height: calc(100% - 55px);
	padding-top: 15px;
	overflow-y: auto;
}

.configuracoes-footer {
	height: 50px;
}

.div-container-card {
	width: 60%;
}

@media only screen and (max-width: 736px) {
	.div-container-card {
		width: 100% !important;
	}

	.card-panel {
		padding: 15px !important;
		border: 0px !important;
	}

	.card {
		padding: 0px !important;
		border: 0px !important;
	}

	.shadow-sm {
		box-shadow: none !important;
	}

	.configuracoes-content {
		padding-top: 0px !important;
	}
}

@media only screen and (max-width: 736px) {
	.btn {
		width: unset;
	}
}
</style>
